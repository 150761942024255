import React, { Component } from "react";
import AppItem from "./AppItem";
import { Route, BrowserRouter as Router } from "react-router-dom";
// import SingleApp from "./SingleApp";

class Portfolio extends Component {
  render() {
    return (
      <Router>
        {/* <Route exact path="/" component={Home} /> */}
        {/* <Route path="/about" component={About} /> */}
        {/* <Route path="/topics" component={Topics} /> */}
        {/* <Route path="/topics/:topicId" component={Topic} /> */}

        {/* <Route exact path={match.url} render={() => <h3>select a topic!</h3>} /> */}
        {/* <Route exact path="/hello" render={() => <h1>select a topic!</h1>} /> */}
        {/* <Route exact path="/test" component={SingleApp} /> */}

        {/* <Link to={`${match.url}/props-v-state`}>props v. state</Link> */}

        <AppItem
          title="Recipe Club"
          icon="rcicon512.png"
          googlePlay="https://play.google.com/store/apps/details?id=com.sneakysharkstudios.recipeclub"
          appStore="https://apps.apple.com/us/app/recipe-club-app/id1513516529"
        />

        <AppItem
          title="Kids Encyclopedia"
          icon="KidsEncyclopedia-512-yellow.png"
          googlePlay="https://play.google.com/store/apps/details?id=com.sneakysharkstudios.kids_encyclopedia"
          appStore="https://apps.apple.com/tt/app/kids-encyclopedia/id1502045040"
        />

        <AppItem
          title="Picture Charades"
          icon="picture-charades-icon.webp"
          googlePlay="https://play.google.com/store/apps/details?id=blueique.studios.picturecharades"
          appStore="https://apps.apple.com/us/app/picture-charades/id1537034574"
        />

        <AppItem
          title="Apache Gunship"
          icon="Apache-Icon-2B-android-512.png"
          googlePlay="https://play.google.com/store/apps/details?id=com.sneakysharkstudios.apachegunship"
          appStore="https://itunes.apple.com/us/app/apache-gunship-1988/id1454089116"
          article="https://gameskeys.net/top-android-game-to-lookout-for-in-june-2020/#ApacheGunship"
        />

        <AppItem
          title="Christmas Crackers"
          icon="cc_icon2.png"
          googlePlay="https://play.google.com/store/apps/details?id=com.sneakysharkstudios.christmascrackers"
        />

        {/* <AppItem
          title="My Digital Life"
          icon="willd_icon.png"
          googlePlay="https://play.google.com/store/apps/details?id=com.mistudios.mydigitallife"
        /> */}

        <AppItem
          title="Binary Gaming Net"
          icon="BGN_Website_Logo.png"
          urlLink="https://binarygaming.net"
        />
      </Router>
    );
  }
}

export default Portfolio;
